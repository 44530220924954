<template>
  <v-container
    v-if="authenticatedStatus === 'success'"
    fluid
    fill-height
    class="d-flex justify-center"
  >
    <div>
      <v-subheader class="justify-center mb-2">
        {{ $t('system.licenses.selectMsg') }}
      </v-subheader>
      <div class="d-flex flex-column">
        <div
          v-for="license in authenticatedUser.licenses"
          :key="license.id"
          :style="{ 'border-left': `solid 5px ${license.theme}` }"
          class="license-item"
          @click="selectLicense(license)"
        >
          {{ license.name }}
        </div>
      </div>
    </div>
  </v-container>
</template>

<script>
import { mapGetters } from 'vuex';
import CookieService from '@/services/cookie';
import LocalStorageService from '@/services/local-storage';

export default {
  name: 'LicenseSelect',
  computed: {
    ...mapGetters(['authenticatedUser', 'authenticatedStatus']),
  },
  methods: {
    selectLicense(license) {
      const redirect = this.$route.query.redirect || '/dashboard';

      this.$store.commit('set_license', license);
      this.$store.commit('projects_request');
      this.$store.commit('reset_tasks_state');
      this.$store.commit('reset_project_state');
      this.$nextTick(() => this.$router.push(redirect));
    },
  },
};
</script>

<style scoped lang="scss">
.ant-card-header {
  text-align: center;
  font-size: 36px;
  text-transform: uppercase;
  color: var(--v-primary-base);
  margin-bottom: 20px;
}

.license-item {
  height: 60px;
  width: 300px;
  border-radius: 8px;
  margin: 7px 0;
  box-shadow: rgba(99, 99, 99, 0.2) 0px 2px 8px 0px;
  transition: 200ms ease-out;
  background-color: white;
  display: flex;
  align-items: center;
  justify-content: center;
  cursor: pointer;

  &:hover {
    box-shadow: rgba(99, 99, 99, 0.2) 0px 2px 8px 0px;
    background-color: #f1f1f1;
    transform: translateX(10px);
  }
}
</style>
